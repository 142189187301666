import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import { AppState } from '@amzn/pvusm-ui-core';
import React from 'react';

type AppStateWithCaptions = Exclude<AppState, AppState.AppInitiated>;

interface LoaderProps {
  appState: AppStateWithCaptions;
}

const loaderCaptionByAppState = {
  [AppState.CheckingAuth]: 'Checking auth',
  [AppState.LoadingAppConfig]: 'Loading config',
  [AppState.LoadingPortal]: 'Loading App',
  [AppState.AppLoadingError]: 'Error',
  [AppState.ApiFailureError]: 'Error',
  [AppState.PageNotFound]: 'Page Not Found',
};

export const AppLoader: React.FC<LoaderProps> = ({ appState }) => (
  <>
    <Column width="100%" alignmentHorizontal="center" alignmentVertical="center" height="100vh">
      <Loader />
      <Text type="b200">{loaderCaptionByAppState[appState]}</Text>
    </Column>
  </>
);