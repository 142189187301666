import '@amzn/pvusm-common-ui-components/dist/styles/CssBaseline';
import { AppState, getMicroAppsLayout, getUniqueBasePaths, publishAppStateChange, registerSingleSpaEvents } from "@amzn/pvusm-ui-core";
import { Hub } from 'aws-amplify/utils';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { navigateToUrl, start } from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";
import { AppStateContainer } from "./components/AppStateContainer";
import { initApp } from "./core/app";
import { injectMicroAppsImportMap, setupSystemJsContentPolicy } from "./core/system";
import { authSessionListener, fetchAppConfig, handleAppLoadWithContext, hasActiveSession } from "./utils/auth";
import { loadApp, registerApplicationWithCustomProps } from "./utils/core";

const initSingleSpa = (applicationContext: any, isLoggedIn: boolean) => {
  const shareScope: string[] = [];
  const microAppLayout = getMicroAppsLayout({ applicationContext, isLoggedIn });
  const routes = constructRoutes(microAppLayout);
  const applications = constructApplications({
    routes,
    loadApp: (appProps) => loadApp(appProps, shareScope),
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });

  applications.forEach((application) => registerApplicationWithCustomProps(application, applicationContext));
  layoutEngine.activate();
  start();

  const redirectionUrl = handleAppLoadWithContext();
  // navigate to auth session validation
  navigateToUrl(redirectionUrl);
  return;
};

const setupApplication = async ({
  appConfigResult,
  isLoggedIn,
  isAppInitiated,
}: any) => {
  const applicationContext = { appConfigResult };
  const { microApps } = appConfigResult;
  publishAppStateChange(AppState.LoadingPortal);
  setupSystemJsContentPolicy();
  await injectMicroAppsImportMap({ microApps });
  initSingleSpa({ ...applicationContext }, isLoggedIn);
};

/**
 * Method which will render app shell react app in container
 * All the global app states like checkAuth, fetchAppConfig, appLoading, appError, notFoundError etc will be render as part of this mini react app
 */
const initAppStateContainerComponent = () => {
  const appStateContainerRoot = createRoot(document.getElementById('pvusm-vendor-ui-portal-container')!);
  appStateContainerRoot.render(React.createElement(React.StrictMode, {}, React.createElement(AppStateContainer)));
};


const init = () => {
  initAppStateContainerComponent();
  publishAppStateChange(AppState.CheckingAuth);

  Hub.listen('auth', authSessionListener);

  hasActiveSession()
    .then(async (isLoggedIn) => {
      console.log('isLoggedIn', isLoggedIn);
      await initApp()
        .then(({ isAppInitiated, appConfigResult }) => {
          const paths = getUniqueBasePaths(appConfigResult.microApps);
          registerSingleSpaEvents(paths);
          publishAppStateChange(AppState.AppInitiated);
          setupApplication({ isLoggedIn, isAppInitiated, appConfigResult });
        });
    })
    .catch((error) => {
      console.error(error);
      publishAppStateChange(AppState.ApiFailureError);
    });
};

fetchAppConfig()
  .then(() => init());
