import React, { useEffect, useState } from 'react';

import { AppLoader } from './AppLoader';
import { AppState, AppStateEvent } from '@amzn/pvusm-ui-core';

export const AppStateContainer: React.FC = () => {
  const [currentAppState, setCurrentAppState] = useState<AppState>();
  useEffect(() => {
    window.addEventListener('pvusm-ui-portal-app:app-state-change', (event) => {
      const customEvent = event as AppStateEvent;
      const { appState } = customEvent.detail;
      console.log(appState);

      setCurrentAppState(appState);
    });
  }, []);
  return (
      <>
        {currentAppState === AppState.AppInitiated && null}
        {currentAppState === AppState.LoadingPortal && <AppLoader appState={currentAppState} />}
        {(currentAppState === AppState.AppLoadingError || currentAppState === AppState.ApiFailureError) && (
          <div>
            API Error
          </div>
        )}
        {currentAppState === AppState.PageNotFound && (
          <div>
            Error
          </div>
        )}
      </>
  );
};