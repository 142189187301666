import { AppProps, Application, registerApplication } from 'single-spa';
import { constructApplications } from 'single-spa-layout';
import {
  MicroAppAdditionalContext,
  MicroAppConfig,
  MicroAppApplicationContext,
  ThemeMode,
  SystemJsImportTarget,
  User, PredefinedThemePalettes, UserPreferences, PortalType,
  getBasePath
} from '@amzn/pvusm-ui-core';

const getUserContext = (): (User | undefined) => {
  // Fetch user Details from API
  return undefined;
};

const getUserPreferences = (): (UserPreferences | undefined) => {
  return {
    theme: {
      mode: ThemeMode.LIGHT,
      palette: PredefinedThemePalettes.BLUE,
    },
    locale: {
      lang: 'en'
    },
  }
};

export const getCustomAppProps = (
  importName: string,
  { appConfigResult }: MicroAppApplicationContext
): MicroAppAdditionalContext => {
  const hasMatchingName = (app: MicroAppConfig) => {
    const { appName, module }: { appName: string; module?: string } = app;
    return importName === `${appName}/${module || 'micro-app-index'}`;
  };

  const enabledMicroApps = appConfigResult?.microApps ?? [];
  const microApp = enabledMicroApps.find(hasMatchingName);
  const { basePaths, appName = '' } = microApp ?? ({} as MicroAppConfig);

  const basePath = getBasePath(basePaths);
  const userContext = getUserContext();
  const userPreferences = getUserPreferences();

  return {
    additionalContext: {
      appId: importName,
      portalType: PortalType.VENDOR,
      basePath,
      microAppName: appName,
      context: {
        userContext,
        userPreferences,
        isLoggedIn: false,
      },
    }
  };
};

export const registerApplicationWithCustomProps = (
  application: ReturnType<typeof constructApplications>[number],
  applicationContext: MicroAppApplicationContext
): void => {
  console.log('application', application);
  registerApplication({
    ...application,
    customProps: (name): MicroAppAdditionalContext => {
      const customProps = getCustomAppProps(name, applicationContext);
      return customProps;
    },
  });
};

export const loadApp = async ({ name }: AppProps, shareScope: string[]): Promise<Application> => {
  const [packageName, moduleName] = name.split('/');
  const app = await System.import<SystemJsImportTarget>(packageName);
  try {
    app.init(shareScope);
  } catch (e) {
    // already was initialized
  }
  const module = await app.get(`./${moduleName}`);

  return module();
};