import { AppState, publishAppStateChange } from '@amzn/pvusm-ui-core';
import { Amplify } from "aws-amplify";
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

/*
* Amplify Tree shaking related issue
* https://github.com/aws-amplify/amplify-js/issues/13688#issuecomment-2287185836
*
*/
import "aws-amplify/auth/enable-oauth-listener";
import { CookieStorage } from 'aws-amplify/utils';
import { navigateToUrl } from 'single-spa';

type AppSyncConfig = {
    endpoint: string;
    region: string;
};

export const refreshSessionAutomatically = async () => {
    try {
        const authSession = (await fetchAuthSession());
        console.log('tokens', authSession);
        const { accessToken, idToken } = authSession?.tokens ?? {};
        if (accessToken?.toString() && idToken?.toString()) {
            sessionStorage.setItem('accessToken', accessToken.toString());
            sessionStorage.setItem('idToken', idToken.toString());
        } else {
            throw new Error('RefreshSessionAutomatically: Unable to refresh Token');
        }
    } catch (e) {
        // TODO: Log Info in RUM
        console.log('Unable to refresh Token', e);
        const redirectUrl = handleAppLoadWithContext();
        navigateToUrl(redirectUrl);
    }
};

export const authSessionListener = (data: any) => {
    const { payload } = data;
    console.log(data);
    switch (payload.event) {
        case 'signedIn':
            console.log('user have been signedIn successfully.');
            break;
        case 'signedOut':
            console.log('user have been signedOut successfully.');
            refreshSessionAutomatically();
            break;
        case 'tokenRefresh':
            console.log('auth tokens have been refreshed.');
            break;
        case 'tokenRefresh_failure':
            console.log('failure while refreshing auth tokens.');
            const redirectUrl = handleAppLoadWithContext();
            navigateToUrl(redirectUrl);
            break;
        case 'signInWithRedirect':
            console.log('signInWithRedirect API has successfully been resolved.');
            break;
        case 'signInWithRedirect_failure':
            console.log('failure while trying to resolve signInWithRedirect API.');
            break;
        case 'customOAuthState':
            console.info('custom state returned from CognitoHosted UI');
            break;
    }
};

const REDIRECTION_FREE_URLS = ['/auth/unauthorized', '/auth/signed-out', '/auth/redirect'];
const LOGIN_PATH = '/auth/redirect';

/*
* Validates user session, preserves url context
* and redirects to cognito hosted UI for login,
* which then redirects to the initial url post authentication
*
*/
export const handleAppLoadWithContext = () => {
    const { search, pathname, origin } = window.location;
    console.log('pathname', location.pathname);

    let redirectTo = '';
    if (REDIRECTION_FREE_URLS.includes(pathname)) {
        return `${origin}${pathname}${search}`;
    } else {
        redirectTo = `${pathname}${search}`;
    }
    let redirectionUrl = LOGIN_PATH;
    if (!!redirectTo) {
        redirectionUrl = `${redirectionUrl}?redirect_to=${window.encodeURIComponent(redirectTo)}`;
    }
    console.info('Redirecting to', redirectionUrl);
    return redirectionUrl;
}

export function configureAmplify(config: any) {
    const { region, endpoint, cognitoConfig } = config;
    // TODO: to be removed post full setup of UI -> MW
    console.log('config', config);
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: cognitoConfig.userPoolId,
                userPoolClientId: cognitoConfig.appClientId,
                loginWith: {
                    oauth: {
                        scopes: cognitoConfig.oauth.scopes,
                        domain: cognitoConfig.oauth.domain,
                        redirectSignIn: cognitoConfig.oauth.redirectSignIn,
                        redirectSignOut: cognitoConfig.oauth.redirectSignOut,
                        responseType: cognitoConfig.oauth.responseType,
                    },
                },
            },
        },
    });
    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
}

export const fetchAppConfig = async (): Promise<AppSyncConfig> => {
    const appSyncResponse = await fetch("/config/appSyncConfig.json");
    const appSyncConfig = await appSyncResponse.json();
    configureAmplify(appSyncConfig);
    return appSyncConfig;
};

export const getUser = async (cb?: Function): Promise<any> => {
    try {
        const currentUser = await getCurrentUser();
        return currentUser;
    } catch (error) {
        console.error(error);
        if (cb !== undefined) {
            cb();
        }
        console.log("User is not signed in");
        return;
    }
};

async function getUserSessionToken() {
    try {
        const { tokens } = await fetchAuthSession();
        if (tokens) {
            const { accessToken, idToken } = tokens;
            sessionStorage.setItem('accessToken', accessToken.toString());
            sessionStorage.setItem('idToken', idToken?.toString() ?? '');
            return idToken?.toString();
        }
        return;
    } catch (err) {
      // TODO: Add RUM integration
        console.error('User is not signed in', err);
        return;
    }
}

export const hasActiveSession = async () => {
    publishAppStateChange(AppState.CheckingAuth);
    console.log('Checking Session');
    const token = await getUserSessionToken();
    return token !== undefined && token !== "";
};